import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import HttpStatus from 'model/enums/http-status';
import { IReturnMessage } from 'model/financial';
import toastUtils from 'shared/util/toast-utils';
import feedOrdersApi from '../../api/feed-order-api';
import {
  IFeedOrder,
  IHystoryFeedOrder,
  TypeActivities,
  TypeFeedProducts,
  TypeFeedStockProducts,
  TypeNewFeedOrder,
  TypePayments,
  TypeProperties,
} from '../../model/feed-orders';

export const feedOrdersService = () => {
  const getMyFeedOrders = async (register: string) => {
    try {
      const result: AxiosResponse<IHystoryFeedOrder[]> = await feedOrdersApi.getMyFeedOrders(register);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const deleteNewFeedOrder = async (orderId: string) => {
    try {
      const result: AxiosResponse<IReturnMessage> = await feedOrdersApi.deleteNewFeedOrder(orderId);
      if (result.status === HttpStatus.OK) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.orderCanceled'));
        return;
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderDeleted'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPropertiesOptions = async (register: string) => {
    try {
      const result: AxiosResponse<TypeProperties[]> = await feedOrdersApi.getPropertiesOptions(register);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFeedProducts = async (bagged: string, firstTypeActivity: string, secondTypeActivity: string) => {
    try {
      const result: AxiosResponse<TypeFeedProducts[]> = await feedOrdersApi.getFeedProducts(bagged, firstTypeActivity, secondTypeActivity);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getActivities = async (register: string) => {
    try {
      const result: AxiosResponse<TypeActivities[]> = await feedOrdersApi.getActivities(register);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPayments = async (register: string, activity: string, deliveryDate: string) => {
    try {
      const result: AxiosResponse<TypePayments[]> = await feedOrdersApi.getPayments(register, activity, deliveryDate);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getOnlyFeedProducts = async (bagged: string, firstTypeActivity: string, secondTypeActivity: string) => {
    try {
      const result: AxiosResponse<TypeFeedProducts[]> = await feedOrdersApi.getOnlyFeedProducts(
        bagged,
        firstTypeActivity,
        secondTypeActivity
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getLastProducts = async (register: string) => {
    try {
      const result: AxiosResponse<TypeFeedProducts[]> = await feedOrdersApi.getLastProducts(register);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendOrder = async (orderList: { itens: IFeedOrder[] }) => {
    try {
      const result: AxiosResponse<IReturnMessage> = await feedOrdersApi.sendOrder(orderList);
      if (result.status === 200) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.orderCreated'));
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderCreated'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFeedStockProduct = async (register: string, product: string, deliveryDate) => {
    try {
      const result: AxiosResponse<TypeFeedStockProducts> = await feedOrdersApi.getFeedStockProduct(register, product, deliveryDate);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getMyFeedOrders,
    deleteNewFeedOrder,
    getPropertiesOptions,
    getFeedProducts,
    getActivities,
    getPayments,
    getOnlyFeedProducts,
    getLastProducts,
    sendOrder,
    getFeedStockProduct,
  };
};

export default feedOrdersService();
